import React, {
  useContext,
  useReducer,
  useState,
  useEffect,
  useRef,
} from "react";
import axios from "axios";
import Post from "../Components/Post";
import LoadingBox from "../LoadingError/LoadingBox";
import { Helmet } from "react-helmet-async";
import MessageBox from "../LoadingError/MessageBox";
import { Store } from "../Store";
import { Link, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import MapsView from "../Components/MapsView";
import InfiniteScroll from "react-infinite-scroll-component";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  borderRadius: "0.5rem",
  outline: "none",
  p: 4,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loading: true };
    case "FETCHED":
      return { ...state, posts: action.payload, loading: false };
    case "FETCH_FAILED":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const reducer2 = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loadingUser: true };
    case "FETCHED":
      return { ...state, dataUser: action.payload, loadingUser: false };
    case "FETCH_FAILED":
      return { ...state, loadingUser: false, errorUser: action.payload };
    default:
      return state;
  }
};

function HomeScreen(props) {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const currentSwitch = props.statePropSd;
  const searchInput = props.statePropSearch;
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const [showSignOutModal, setShowSignOutModal] = useState(false);

  const [pageSeek, setPageSeek] = useState(1);
  const [hasMoreSeek, setHasMoreSeek] = useState(true);

  const [showModalAdd, setShowModalAdd] = useState(false);
  const [isShowMap, setIsShowMap] = useState(false);

  const [description, setDescription] = useState("");

  const [{ loading, error, posts }, dispatch] = useReducer(reducer, {
    posts: [],
    loading: true,
    error: "",
  });

  const [allPost, setAllPost] = useState([]);

  const [allPostSeek, setAllPostSeek] = useState([]);

  const [{ dataUser, loadingUser }, dispatch2] = useReducer(reducer2, {
    dataUser: [],
    loadingUser: false,
    errorUser: "",
  });

  // Mengambil data post berdasarkan halaman, pencarian, dan switch saat ini
  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "FETCHING" });

      let userId = null;
      if (dataUser) {
        userId = dataUser.user_id;
      }

      let tempPage = currentSwitch === 1 ? page : pageSeek;

      let tempTypePost =
        currentSwitch === 1
          ? [
            { post_type: 1, sd_post_type: 1 },
            { post_type: 2, sd_post_type: 1 },
            { post_type: 3, sd_post_type: 1 },
            { post_type: 4, sd_post_type: 1 },
            { post_type: 5, sd_post_type: 1 },
          ]
          : [
            { post_type: 1, sd_post_type: 2 },
            { post_type: 2, sd_post_type: 2 },
            { post_type: 3, sd_post_type: 2 },
            { post_type: 4, sd_post_type: 2 },
            { post_type: 5, sd_post_type: 2 },
          ];

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_DOMAIN
          }/api/posts/search?query=${searchInput}&row=10&page=${tempPage}&userId=${userId}&typePost=${JSON.stringify(
            tempTypePost
          )}`,
          {
            auth: {
              username: `${process.env.REACT_APP_BASIC_AUTH_USERNAME}`,
              password: `${process.env.REACT_APP_BASIC_AUTH_PASSWORD}`,
            },
          }
        );

        if (response.data.data.length < 10) {
          if (currentSwitch === 1) {
            setHasMore(false);
          } else {
            setHasMoreSeek(false);
          }
        }

        dispatch({
          type: "FETCHED",
          payload: [...posts, ...response.data.data],
        });

        if (allPost.length === 0 && currentSwitch === 1) {
          setAllPost((prevPost) => [...prevPost, ...response.data.data]);
        } else if (allPostSeek.length === 0 && currentSwitch === 2) {
          setAllPostSeek((prevPost) => [...prevPost, ...response.data.data]);
        }
      } catch (err) {
        dispatch({
          type: "FETCH_FAILED",
          payload: err.message,
        });
      }
    };

    if (
      !loadingUser &&
      ((currentSwitch === 1 && allPost.length === 0) ||
        (currentSwitch === 2 && allPostSeek.length === 0))
    ) {
      fetchData();
    }
  }, [searchInput, page, pageSeek, dataUser, currentSwitch]);

  const loadMorePosts = () => {
    if (
      (hasMore && currentSwitch === 1) ||
      (hasMoreSeek && currentSwitch === 2)
    ) {
      let tempPage = currentSwitch === 1 ? page + 1 : pageSeek + 1;

      const fetchData = async () => {
        let userId = null;
        if (dataUser) {
          userId = dataUser.user_id;
        }

        let tempTypePost =
          currentSwitch === 1
            ? [
              { post_type: 1, sd_post_type: 1 },
              { post_type: 2, sd_post_type: 1 },
              { post_type: 3, sd_post_type: 1 },
              { post_type: 4, sd_post_type: 1 },
              { post_type: 5, sd_post_type: 1 },
            ]
            : [
              { post_type: 1, sd_post_type: 2 },
              { post_type: 2, sd_post_type: 2 },
              { post_type: 3, sd_post_type: 2 },
              { post_type: 4, sd_post_type: 2 },
              { post_type: 5, sd_post_type: 2 },
            ];

        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_DOMAIN
            }/api/posts/search?query=${searchInput}&row=10&page=${tempPage}&userId=${userId}&typePost=${JSON.stringify(
              tempTypePost
            )}`,
            {
              auth: {
                username: `${process.env.REACT_APP_BASIC_AUTH_USERNAME}`,
                password: `${process.env.REACT_APP_BASIC_AUTH_PASSWORD}`,
              },
            }
          );

          if (response.data.data.length < 10) {
            if (currentSwitch === 1) {
              setHasMore(false);
            } else {
              setHasMoreSeek(false);
            }
          }

          if (currentSwitch === 1) {
            setAllPost((prevPost) => [...prevPost, ...response.data.data]);
          } else {
            setAllPostSeek((prevPost) => [...prevPost, ...response.data.data]);
          }
        } catch (err) { }
      };

      fetchData();

      if (currentSwitch === 1) {
        setPage((prevPage) => prevPage + 1);
        // Only increment page if there are more posts to load
      } else {
        setPageSeek((prevPage) => prevPage + 1);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (userInfo !== null) {
        dispatch2({ type: "FETCHING" });

        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/me`,
            {
              headers: { Authorization: `Bearer ${userInfo.data.token}` },
            }
          );

          dispatch2({ type: "FETCHED", payload: data.data });
        } catch (error) {
          dispatch2({ type: "FETCH_FAILED", payload: error.message });
        }
      }
    };

    fetchData();
  }, []);

  // useEffect(() => {

  // }, [allPost,allPostSeek, currentSwitch]);

  function handleCloseModalAdd() {
    setShowModalAdd(false);
  }

  const handleCancelButtonModalAdd = () => {
    setShowModalAdd(false);
  };

  function handleSignInButtonModalAdd() {
    navigate("/signin");
  }

  function SetNoPosts({ myKey }) {
    if (myKey === 1 && allPost.length === 0) {
      return <p className="mt-5">No post exists!</p>;
    } else if (myKey === 2 && allPostSeek.length === 0) {
      return <p className="mt-5">No post exists!</p>;
    } else {
      return <div></div>;
    }
  }

  const toggleModal = () => {
    setShowSignOutModal(!showSignOutModal);
  };

  function handleCloseSignOutModal() {
    setShowSignOutModal(false);
  }

  function handleCancelButtonSignOutModal() {
    setShowSignOutModal(false);
  }

  function SetProfilePicture() {
    const isNull = dataUser.picture;
    if (isNull === "null" || isNull === null) {
      return <DefaultPP />;
    } else {
      return <ProfilePicture />;
    }
  }

  function DefaultPP() {
    return (
      <img
        className="mb-3 mt-3 rounded-full w-12 h-12"
        src="../images/pp/defaultpp.png"
        alt="defaultprofilepicture"
        referrerPolicy="no-referrer"
      />
    );
  }

  function ProfilePicture() {
    return (
      <img
        className="mb-3 mt-3 rounded-full w-12 h-12"
        src={dataUser.picture}
        alt="profilepicture"
        referrerPolicy="no-referrer"
      />
    );
  }

  return (
    <>
      <div className="h-full">
        <Helmet>
          <title>Home | SiapHub</title>
        </Helmet>
        <div className="grid grid-cols-3 gap-4"></div>
        <div className="h-full">
          {loading ? (
            <div className="flex justify-center pt-6 ">
              <LoadingBox />
            </div>
          ) : error ? (
            <MessageBox severity="error">{error}</MessageBox>
          ) : isShowMap ? (
            // <div className="flex h-full flex-col">
            <>
              <div
                className="fixed w-8 h-8 top-16 left-2 z-[401] shadow-md flex justify-center items-center cursor-pointer py-1 bg-white rounded-lg"
                onClick={() => setIsShowMap(false)}
              >
                <svg
                  width="30"
                  height="26"
                  viewBox="0 0 30 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="scale-50"
                >
                  <path
                    d="M12.8878 25.6637C13.3877 26.128 14.179 26.1092 14.6552 25.6217C15.1313 25.1342 15.112 24.3625 14.6122 23.8981L4.19312 14.2191H28.75C29.4403 14.2191 30 13.6733 30 13C30 12.3268 29.4403 11.7809 28.75 11.7809H4.19295L14.6122 2.10183C15.112 1.63745 15.1312 0.865796 14.6552 0.378272C14.179 -0.109236 13.3877 -0.12801 12.8878 0.336367L0.522983 11.823C0.241433 12.0846 0.0748501 12.4181 0.0232168 12.765C0.00798347 12.841 0 12.9197 0 13C0 13.0806 0.00801652 13.1593 0.0232999 13.2355C0.0750332 13.5823 0.2416 13.9156 0.522983 14.177L12.8878 25.6637Z"
                    fill="black"
                  />
                </svg>
              </div>
              <div className="h-full">
                <MapsView posts={currentSwitch === 1 ? allPost : allPostSeek} />
              </div>
            </>
          ) : (
            // </div>
            <>
              {currentSwitch === 1 ? (
                <div className="h-full">
                  <div className="sm:mb-12 h-full">
                    <InfiniteScroll
                      dataLength={allPost.length}
                      next={loadMorePosts}
                      scrollThreshold={0.9}
                      hasMore={hasMore}
                      // height={
                      //   window.innerHeight > 870
                      //     ? window.innerHeight - 80
                      //     : window.innerHeight - 100
                      // }
                      height={"92vh"}
                      loader={
                        <div className="flex justify-center mt-6 mb-14 md:mb-6 ">
                          <LoadingBox />
                        </div>
                      }
                    >
                      <div className="flex justify-center mb-20 lg:mb-0 ">
                        <div className="grow flex justify-center lg:mr-3 lg:justify-end mb-2">
                          <div className="flex-col flex-wrap">

                            {!userInfo ? (
                              <></>
                            ) : (
                              <div className="card-new-post xs:w-screen-90 relative overflow-hidden ">
                                <div class="flex">
                                  <div class="flex-none w-14">
                                    <SetProfilePicture />
                                  </div>
                                  <div class="flex-initial w-96">
                                    <div className="card-input-post text-gray-400" onClick={toggleModal}>
                                      What's on your mind, {dataUser.name}?
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            {/* {dataUser.picture} */}

                            {allPost.length > 0 ? (
                              allPost.map((post, index) => (
                                <Post key={post.slug} post={post}></Post>
                              ))
                            ) : (
                              <SetNoPosts myKey={1} />
                            )}
                          </div>
                        </div>

                        <div className="flex-col flex-wrap w-2/5 lg:mx-3 hidden mt-3 lg:flex sticky top-3 h-[90vh]">
                          <MapsView posts={allPost} />
                        </div>
                      </div>
                    </InfiniteScroll>
                  </div>

                  <div
                    className="fixed bottom-20 right-2 w-10 h-10 rounded-full bg-white flex lg:hidden justify-center items-center z-10 text-gray-500"
                    style={{ boxShadow: "0px 2px 2px 2px gainsboro" }}
                    onClick={() => setIsShowMap(true)}
                  >
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className={`scale-125 hover:cursor-pointer`}
                    >
                      <circle cx="15" cy="15" r="15" fill="none" />
                      <path
                        d="M15 7C17.2 7 19 8.8 19 11C19 13.1 16.9 16.5 15 18.9C13.1 16.4 11 13.1 11 11C11 8.8 12.8 7 15 7ZM15 5C11.7 5 9 7.7 9 11C9 15.5 15 22 15 22C15 22 21 15.4 21 11C21 7.7 18.3 5 15 5ZM15 9C13.9 9 13 9.9 13 11C13 12.1 13.9 13 15 13C16.1 13 17 12.1 17 11C17 9.9 16.1 9 15 9ZM23 22C23 24.2 19.4 26 15 26C10.6 26 7 24.2 7 22C7 20.7 8.2 19.6 10.1 18.8L10.7 19.7C9.7 20.2 9 20.8 9 21.5C9 22.9 11.7 24 15 24C18.3 24 21 22.9 21 21.5C21 20.8 20.3 20.2 19.2 19.7L19.8 18.8C21.8 19.6 23 20.7 23 22Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                </div>
              ) : (
                <div className="h-full">
                  <div className="sm:mb-5 h-full">
                    <InfiniteScroll
                      dataLength={allPostSeek.length}
                      next={loadMorePosts}
                      scrollThreshold={0.9}
                      hasMore={hasMoreSeek}
                      // height={
                      //   window.innerHeight > 870
                      //     ? window.innerHeight - 80
                      //     : window.innerHeight - 100
                      // }
                      height={"92vh"}
                      loader={
                        <div className="flex justify-center mt-6 mb-14 md:mb-6 ">
                          <LoadingBox />
                        </div>
                      }
                    >
                      <div className="flex justify-center ">
                        <div className="grow flex justify-center lg:mr-3 lg:justify-end mb-2">
                          <div className="flex-col flex-wrap">

                            {!userInfo ? (
                              <></>
                            ) : (
                              <div className="card-new-post xs:w-screen-90 relative overflow-hidden ">
                                <div class="flex">
                                  <div class="flex-none w-14">
                                    <SetProfilePicture />
                                  </div>
                                  <div class="flex-initial w-96">
                                    <div className="card-input-post text-gray-400" onClick={toggleModal}>
                                      What's on your mind, {dataUser.name}?
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            {allPostSeek.length > 0 ? (
                              allPostSeek.map((post, index) => (
                                <Post key={post.slug} post={post}></Post>
                              ))
                            ) : (
                              <SetNoPosts myKey={2} />
                            )}
                          </div>
                        </div>

                        <div className="flex-col flex-wrap w-2/5 lg:mx-3 hidden mt-3 lg:flex sticky top-3 h-[90vh]">
                          <MapsView posts={allPostSeek} />
                        </div>
                      </div>

                    </InfiniteScroll>
                  </div>

                  <div
                    className="fixed bottom-20 right-2 w-10 h-10 rounded-full bg-white flex lg:hidden justify-center items-center z-10 text-gray-500"
                    style={{ boxShadow: "0px 2px 2px 2px gainsboro" }}
                    onClick={() => setIsShowMap(true)}
                  >
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className={`scale-125 hover:cursor-pointer`}
                    >
                      <circle cx="15" cy="15" r="15" fill="none" />
                      <path
                        d="M15 7C17.2 7 19 8.8 19 11C19 13.1 16.9 16.5 15 18.9C13.1 16.4 11 13.1 11 11C11 8.8 12.8 7 15 7ZM15 5C11.7 5 9 7.7 9 11C9 15.5 15 22 15 22C15 22 21 15.4 21 11C21 7.7 18.3 5 15 5ZM15 9C13.9 9 13 9.9 13 11C13 12.1 13.9 13 15 13C16.1 13 17 12.1 17 11C17 9.9 16.1 9 15 9ZM23 22C23 24.2 19.4 26 15 26C10.6 26 7 24.2 7 22C7 20.7 8.2 19.6 10.1 18.8L10.7 19.7C9.7 20.2 9 20.8 9 21.5C9 22.9 11.7 24 15 24C18.3 24 21 22.9 21 21.5C21 20.8 20.3 20.2 19.2 19.7L19.8 18.8C21.8 19.6 23 20.7 23 22Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <Modal
        open={showSignOutModal}
        onClose={handleCloseSignOutModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-notification-style p-5 bg-white rounded-lg shadow-lg max-w-md mx-auto">
          <Typography id="modal-modal-title" component="h1" className="text-xl font-semibold mb-4">
            {/* Input for title */}
            <input
              className="title bg-gray-100 border border-gray-300 p-2 mb-4 w-full outline-none rounded-md"
              spellCheck="false"
              placeholder="Title"
              type="text"
            />

            {/* Textarea for description */}
            <textarea
              className="description bg-gray-100 sec p-3 h-40 border border-gray-300 w-full outline-none rounded-md"
              style={{ resize: "none" }}
              spellCheck="false"
              placeholder="Describe everything about this post here"
            ></textarea>

            {/* Character count */}
            <div className="icons flex text-gray-500 m-2">
              <div className="count ml-auto text-gray-400 text-xs font-semibold">
                {description.length}/300
              </div>
            </div>
          </Typography>

          {/* Modal buttons */}
          <div className="flex justify-end">
            <button
              type="button"
              className="py-2 px-4 rounded-lg text-sm bg-white text-[#F95C3D] shadow hover:bg-[#F5F5F5] outline-none focus:outline-none ml-2 mb-1 ease-linear transition-all duration-150 mr-4"
              onClick={handleCancelButtonSignOutModal}
            >
              Cancel
            </button>
            <button
              type="button"
              className="py-2 px-4 rounded-lg text-sm bg-[#F95C3D] text-white shadow hover:bg-orange-600 outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150"
              onClick={handleCancelButtonSignOutModal}
            >
              Post
            </button>

          </div>
        </Box>
      </Modal>
    </>
  );
}

export default HomeScreen;
